import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Routing from './routing/Routing';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { legacy_createStore as createStore} from 'redux'
import { Provider } from 'react-redux';
import { rootReducer } from './rootReducer';

function App() {

    const store = createStore(rootReducer);

    const breakpoints = {
        xs: '480px',
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
    }

    const theme = extendTheme({breakpoints},{colors: {
        sofitex: {
          500: "#03b403",
        },
    }},)

    return (
        <div className="App">
            <Provider store={store}>      
                <ChakraProvider theme={theme}>
                    <Router>
                      <Routing/>
                    </Router>     
                    <ToastContainer 
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        theme="dark"
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </ChakraProvider>
            </Provider>
        </div>
    );

}

export default App;
