import Axios from 'axios';
import ApiConfigUrl from './ApiConfigUrl';

// Création de l'instance d'Axios + configuration de la base URL des requêtes
const ApiBeetween = Axios.create({
    baseURL:  ApiConfigUrl,
})

// Interception des requêtes avant envoi pour y insérer l'autorisation avec le jwt dans l'entête
ApiBeetween.interceptors.request.use(req => {
    req.headers['Authorization'] = "Bearer eyJUT0tFTl9UWVBFIjoiUmVmcmVzaCIsImFsZyI6IkhTNTEyIn0.eyJzdWIiOiJwcGVudGllckBzb2ZpdGV4LmZyIiwiZXhwIjoxNzk1MzQzODcxLCJpYXQiOjE3MzIyNzE4NzEsImp0aSI6Im5ybXE5bmtlYzlxdmowbnRrdG92MzBtcG1iIn0.EGlp-yks6hUOI_DOVSvxodKZ8PLP4Q31b4vgpwwsxEglwsPkgd6ToOmXgIyOCuzdDceEfoMMAbT6gLoq021kmA";
    req.headers['accept'] = "application/json";
    return req
})

export default ApiBeetween;